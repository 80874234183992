import PQueue from 'p-queue';
import type { Analytics as AnalyticsSDK } from '@super-protocol/sdk-js';

export interface Config {
  apiKey: string;
  apiUrl: string;
  userId: string;
  deviceId: string;
}

export class Analytics {
  private static instance: Analytics;
  private service?: AnalyticsSDK<number[]> | null = null;
  private userId?: string | null = null;
  private queue: PQueue = new PQueue({ concurrency: 1 });
  private events: Array<{ eventName: string; eventProperties?: string | Record<string, any>; }> = [];
  private flushTimer?: number;
  private initializingPromise?: Promise<void>; // save previous initalizing process (prevent multiple initialization)
  private readonly flushInterval = 100;
  private readonly maxRetries = 2;

  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }
    return Analytics.instance;
  }

  public isInitialized(): boolean {
    return !!this.service;
  }

  private throwIfNoInitialized() {
    if (!this.isInitialized()) {
      throw new Error('Analytics service is not initialized');
    }
  }

  public async initialize(config: Config): Promise<Analytics> {
    const {
      apiKey, userId, deviceId, apiUrl,
    } = config;

    if (this.initializingPromise) {
      await this.initializingPromise;
      return Analytics.getInstance();
    }

    if (this.isInitialized() && this.userId === userId) {
      return Analytics.getInstance();
    }

    this.initializingPromise = (async () => {
      // send previous events
      await this.shutdown();

      const { Analytics: AnalyticsSDK, BrowserEventProvider } = await import('@super-protocol/sdk-js');
      const browserEventProvider = new BrowserEventProvider({
        userId: userId || '',
        deviceId,
        platform: 'web-ai',
      });

      this.service = new AnalyticsSDK({
        apiUrl,
        apiKey,
        eventProvider: browserEventProvider,
      });

      this.userId = userId;
    })();

    await this.initializingPromise;
    this.initializingPromise = undefined;

    return Analytics.getInstance();
  }

  public trackEvent(eventName: string, eventProperties?: string | Record<string, any>): void {
    this.throwIfNoInitialized();

    this.events.push({ eventName, eventProperties });

    if (!this.flushTimer) {
      this.flushTimer = window.setTimeout(() => this.flushEvents(), this.flushInterval);
    }
  }

  private flushEvents() {
    if (!this.events.length) return;

    const eventsToSend = [...this.events];
    this.events = [];
    this.flushTimer = undefined;

    this.queue.add(this.sendEventsWithRetries.bind(this, eventsToSend));
  }

  private async sendEventsWithRetries(
    events: Array<{ eventName: string; eventProperties?: string | Record<string, any>; }>,
  ): Promise<void> {
    let attempt = 0;

    while (attempt < this.maxRetries) {
      try {
        return await this.trackEvents(events);
      } catch (error) {
        attempt++;
        console.error(`Failed to send events after ${attempt} attempts`, error);
      }
    }
  }

  private async trackEvents(
    events: Array<{ eventName: string; eventProperties?: string | Record<string, string>; }>,
  ): Promise<void> {
    this.throwIfNoInitialized();
    await this.service?.trackEvents({ events });
  }

  public async shutdown(): Promise<void> {
    if (this.flushTimer) {
      window.clearTimeout(this.flushTimer);
      this.flushTimer = undefined;
    }
    this.flushEvents();
    await this.queue.onIdle();
    this.userId = null;
    this.service = null;
  }
}