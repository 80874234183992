import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { Storage } from '../types';
import { GlobalSessionState } from './types';

const initialState: GlobalSessionState = {
  busySecretKeeperConnection: false,
};

export const globalSession = createSlice({
  name: 'globalSession',
  initialState,
  reducers: {
    setBusySecretKeeperConnection(state, action: PayloadAction<boolean>) {
      state.busySecretKeeperConnection = action.payload;
    },
  },
  selectors: {
    busySecretKeeperConnectionSelector: (state) => state.busySecretKeeperConnection,
  },
});

export const {
  setBusySecretKeeperConnection,
} = globalSession.actions;

export const {
  busySecretKeeperConnectionSelector,
} = globalSession.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'globalSession', storage, whitelist: ['busySecretKeeperConnection'],
}, globalSession.reducer);
