import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { Storage } from '../types';
import { getInitialState } from './helpers';
import migrations from './migrations';
import { AnnouncementsState } from './types';

const initialState: AnnouncementsState = getInitialState();

export const announcements = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    setFirstTimeVisited(state) {
      state.firstTimeVisited = true;
      state.isOpen = false;
    },
    setLastUpdateDate(state, action: PayloadAction<string>) {
      state.lastUpdateDate = action.payload;
    },
    closeAnnouncements(state) {
      state.isOpen = false;
    },
    openAnnouncements(state) {
      state.isOpen = true;
    },
  },
  selectors: {
    announcementsSelector: (state) => (state),
  },
});

export const {
  setFirstTimeVisited,
  setLastUpdateDate,
  closeAnnouncements,
  openAnnouncements,
} = announcements.actions;

export const {
  announcementsSelector,
} = announcements.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'announcements', storage, whitelist: ['firstTimeVisited', 'lastUpdateDate'], migrate: migrations, version: 0,
}, announcements.reducer);
